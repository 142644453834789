import React from 'react';
import arrow from "../../../img/arrow-right-white.svg";

const ArrowLeft = ({ goPrev }) => {
    return (
        <div className='arrow arrow--left' onClick={ goPrev }>
            <img src={ arrow } alt="arrow" />
        </div>
    )
};

export default ArrowLeft;