import React from 'react';
import './BreadCrumbs.scss';

const BreadCrumbs = props => {
    return (
        <div className="bc-wrap">
            <ul className='bc' itemScope itemType="http://schema.org/BreadcrumbList">
                <li className="bc__item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    <a href='/' itemProp="url">Головна</a>
                </li>
                <li className="bc__item" itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
                    Новини
                </li>
            </ul>
        </div>
    )
};


export default BreadCrumbs;