import React from 'react';

const MainWhyUsItem = props => {
    return (
        <div className="why-us-item">
            <div className="why-us-item__bg">
                <img src={ props.bgImg } alt={ props.title } />
            </div>
            <div className="why-us-item__body">
                <div className="why-us-item__header">
                    <div className="why-us-item__ico">
                        <img src={ props.ico } alt={ props.title } />
                    </div>
                    <h4 className="why-us-item__title">{ props.title }</h4>
                </div>
                <p className="why-us-item__subtitle">
                    { props.subtitle }
                </p>
            </div>
        </div>
    )
}

export default MainWhyUsItem;