import React from 'react';
import BreadCrumbs from "../globalComponents/BreadCrumbs";
import NewsList from "./NewsList";
import './News.scss';
import Pagination from "../globalComponents/Pagination";

const News = () => {
  return (
    <main>
      <BreadCrumbs />
      <div className="container">
        <h1 className="page-title">новини</h1>
        <NewsList />
        <Pagination />
      </div>
    </main>
  )
}

export default News;
