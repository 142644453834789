import React from 'react';
import './ArticleItem.scss';
import commentIco from '../../../img/comment-ico.svg';
import arrow from  '../../../img/arrow-right-small.svg';

const ArticleItem = props => {

    let ItemClassName = 'article-item';
    if (props.type === 'news') {
        ItemClassName += ' news-item';
    } else if (props.type === 'blog') {
        ItemClassName += ' blog-item';
    }

    let showMoreLinkText = '';
    if (props.type === 'news') {
        showMoreLinkText += 'Детальніше';
    } else if (props.type === 'blog') {
        showMoreLinkText += 'читати повністю';
    }


    return (
        <div className={ ItemClassName }>

            <div className="article-item__img">
                <img src={ props.img } alt={ props.title } />
            </div>

            <div className="article-item__body">

                {props.type === 'news' ?
                    <div className="article-item__info">
                        <div className="article-item__post-date">
                            { props.date }
                        </div>
                        <div className="article-item__comments">
                            <img src={ commentIco } alt="comments" />
                            { props.commentsCount }
                        </div>
                    </div>
                    :
                    ''
                }

                <div className="article-item__title">
                    { props.title }
                </div>

                <div className="article-item__subtitle">
                    { props.subtitle }
                </div>
                <a href="/" className="article-item__link">
                    { showMoreLinkText }
                    <button className="article-item__link-btn">
                        <img src={ arrow } alt="arrow" />
                    </button>
                </a>
            </div>
        </div>
    )
}

export default ArticleItem;
