import React from 'react';
import './ArticlePage.scss';
import ArticlePageHeading from "./ArticlePageHeading";
import CommentsSection from "./CommentsSection";

const ArticlePage = props => {

    return (
        <main className="article-page">
            <ArticlePageHeading
                bg={ props.bg }
                category={ props.category }
                title={ props.title }
                date={ props.date }
                commentsCount={ props.commentsCount }
            />
            <article className="article-body container-small">
                { props.children }
            </article>
            <CommentsSection />
        </main>
    )
}

export default ArticlePage;
