import React from 'react';
import HeaderTop from "./HeaderTop";
import HeaderBottom from "./HeaderBottom";
import './Header.scss';

const Header = props => {


    return (
        <header className="header">
            <HeaderTop />
            <HeaderBottom />
        </header>
    )
}

export default Header;
