import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import Main from "./components/Main";
import Header from "./components/globalComponents/Header";
import Footer from "./components/globalComponents/Footer";
import News from "./components/News";
import Blog from "./components/Blog";
import NewsArticle from "./components/NewsArticle/NewsArticle";
import BlogArticle from "./components/BlogArticle/BlogArticle";
import Catalog from "./components/Catalog/Catalog";

const App = props => {



  return (
    // <div className="content">
    //   <Header />
    //   {/*<Main />*/}
    //   {/*<News />*/}
    //   {/*<Blog />*/}
    //   {/*<NewsArticle />*/}
    //   {/*<BlogArticle />*/}
    //   <Catalog />





        <Router>
            <Switch>
                <Route path='/' exact>
                    <nav style={{width: "100%", display: "flex", justifyContent: "center", textAlign: "center", marginTop: "100px" }}>
                        <ul>
                            <li>
                                <Link to="/main">Home</Link>
                            </li>
                            <li>
                                <Link to="/news">News</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                            <li>
                                <Link to="/news-article">News Article</Link>
                            </li>
                            <li>
                                <Link to="/blog-article">Blog Article</Link>
                            </li>
                            <li>
                                <Link to="/catalog">Catalog</Link>
                            </li>
                        </ul>
                    </nav>
                </Route>
                <Route path="/main">
                    <Header />
                    <Main />
                    <Footer />
                </Route>
                <Route path="/news">
                    <Header />
                    <News />
                    <Footer />
                </Route>
                <Route path="/blog">
                    <Header />
                    <Blog />
                    <Footer />
                </Route>
                <Route path="/news-article">
                    <Header />
                    <NewsArticle />
                    <Footer />
                </Route>
                <Route path="/blog-article">
                    <Header />
                    <BlogArticle />
                    <Footer />
                </Route>
                <Route path="/catalog">
                    <Header />
                    <Catalog />
                    <Footer />
                </Route>
            </Switch>
        </Router>











    //   <Footer />
    // </div>

  );
}

export default App;
