import React from 'react';
import HeaderNavData from "./HeaderNavData";

const HeaderNavList = props => {

    return (
        <nav className="header__nav">
            {HeaderNavData.map( item => (
                <div className="header__nav-item">
                    <a href="/">{ item.name }</a>
                </div>
            ))}
        </nav>
    )

}

export default HeaderNavList;
