import React from 'react';
import commentIco from '../../../img/comment-ico.svg';

const ArticlePageHeading = props => {

    const categoryNameSwitch  = (param) => {
        switch(param) {
            case 'news': return 'новини';
            case 'blog': return 'Блог';
            default:
                break;
        }
    }

    return (
        <section className="article-heading">
            <div className="article-heading__bg">
                <img src={ props.bg } alt={ props.title } />
            </div>
            <div className="article-heading__body container-small">
                <h3 className="article-heading__category"> { categoryNameSwitch( props.category ) } </h3>
                <h1 className="article-heading__title"> { props.title } </h1>
                <div className="article-heading__info">
                    <div className="article-heading__post-date">
                        { props.date }
                    </div>
                    <div className="article-heading__comments">
                        <img src={ commentIco } alt="comments" />
                        { props.commentsCount }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ArticlePageHeading;
