import React from 'react'
import BlogData from "./BlogData";
import ArticleItem from "../globalComponents/ArticleItem";

const BlogList = () => {
  return (
        <section className="blog">
            {BlogData.map(item => (
                <ArticleItem { ...item } key={ item.id } />
            ))}
        </section>
  )
}

export default BlogList;
