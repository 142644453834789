import React from 'react';
import HeaderBottomCategoriesData from "./HeaderBottomCategoriesData";

const HeaderBottomCategories = props => {

    return (
        <div className="header-production__categories">
            {HeaderBottomCategoriesData.map( category => (
                <div className="header-production__category">
                    <div className="header-production__img">
                        <img src={ category.img } alt="Координатно-пробивний інструмент" />
                    </div>
                    <div className="header-production__list-wrap">
                        <h4 className="header-production__title">{ category.title }</h4>
                        <ul className="header-production__list">
                            {category.list.map( listItem => (
                                <li className="header-production__list-item">
                                    <a href="/">{ listItem }</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    )

}

export default HeaderBottomCategories;
