import React from 'react';
import CommentsData from "./СommentsData";
import CommentsList from "./CommentsList";

const CommentsSection = props => {

    return (
        <section className="comments container-small">
            <h3 className="comments__title">
                Коментарі
                <div className="comments__title-count">{ CommentsData.length }</div>
            </h3>
            <form action="/" className="comments__form">
                <textarea className="comments__textarea" name="comment" cols="30" rows="10" placeholder="Залишити коментар...">

                </textarea>
                <button className="comments__add-btn" type="submit">коментувати</button>
            </form>
            <CommentsList />
        </section>
    )
}

export default CommentsSection;
