import React from 'react';
import arrow from '../../../img/arrow-right-white.svg';

const MainProductionItem = props => {
    return (
        <a href="/" className="production-item">
            <div className="production-item__header">
                <h4 className="production-item__title">{ props.title }</h4>
                <button className="production-item__link-btn">
                    <img src={ arrow } alt="arrow" />
                </button>
            </div>
            <div className="production-item__img">
                <img src={ props.img } alt={ props.title } />
            </div>
        </a>
    )
}

export default MainProductionItem;