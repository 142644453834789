import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import MainPopularProductsData from './MainPopularProductsData';
import MainPopularProductsItem from './MainPopularProductsItem';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import 'swiper/swiper.scss';


const MainPopularProductsSlider = props => {
    const ref = useRef(null);
    const goNext = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (ref.current !== null && ref.current.swiper !== null) {
            ref.current.swiper.slidePrev();
        }
    };


    const params = {
        spaceBetween: 30,
        // loop: true,
        slidesPerView: 6,
        speed: 600,
        breakpoints: {
        },
        ref: ref
    };

    return (
        <div className="products-slider">
            <div className="arrows">
                <ArrowLeft goPrev={ goPrev } />
                <ArrowRight goNext={ goNext } />
            </div>
            <Swiper
                { ...params }
            >
                {MainPopularProductsData.map(item => (
                    <SwiperSlide key={ item.id }><MainPopularProductsItem { ...item }  /></SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
};

export default MainPopularProductsSlider;