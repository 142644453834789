import React from 'react';
import ArticlePage from "../globalComponents/ArticlePage";
import articleBg from "../../img/articles/news-article-bg.jpg";
import newsImg1 from '../../img/articles/news-article-img-1.jpg';
import newsImg2 from '../../img/articles/news-article-img-2.jpg';
import arrow from '../../img/arrow-right-white.svg';

const NewsArticle = props => {
    const newsArticleData = {
            bg: articleBg,
            category: 'news',
            title: 'МІЖНАРОДНИЙ ПРОМИСЛОВИЙ ФОРУМ-2020 | КИЇВ',
            date: '27 листопада 2020',
            commentsCount: '2'
    };

    return (
        <ArticlePage { ...newsArticleData } >
            <section className="article-section">

                <div className="article-section__img">
                    <img src={ newsImg1 } alt="Міжнародний промисловий форум" />
                </div>
                <div className="article-section__content-wrap">
                    <p className="article-section__p">
                        <span className="article-section__highlight-text">24-27 листопада 2020 року</span> відбулася виставка «Міжнародний промисловий форум» в м. Київ. <br />
                        За традицією, компанія «ВЕКТОРТУЛ»  взяла участь в щорічній і довгоочікуваної виставці  «Міжнародний промисловий форум-2020». Незважаючи на складності сучасного світу, ми раді, що виставка все-таки відбулася.
                    </p>

                    <ul className="article-section__list">
                        <p className="article-section__list-label">На виставці нами був представлений сучасний інструмент Ketec для координатно-пробивних пресів і Tecnostamp для листозгинів таких як:</p>
                        <li className="article-section__list-item">Trumpf</li>
                        <li className="article-section__list-item">Amada</li>
                        <li className="article-section__list-item">Prima-Power</li>
                        <li className="article-section__list-item">Finn-Power</li>
                        <li className="article-section__list-item">Yawei</li>
                        <li className="article-section__list-item">Euromac</li>
                        <li className="article-section__list-item">Tailift</li>
                        <li className="article-section__list-item">Durma</li>
                        <li className="article-section__list-item">Baykal</li>
                    </ul>
                </div>

            </section>

            <section className="article-section">

                <div className="article-section__content-wrap">
                    <p className="article-section__p">
                        Ми щороку знаходимо чим здивувати наших відвідувачів, і цей рік не став винятком.
                        Наша головна мета на кожній виставці – це довготривала і взаємовигідна співпраця. І завдяки Міжнародному промисловому
                        форумі ми в черговий раз здобули відмінних партнерів, яким гарантуємо високу якість інструменту і професійний сервіс.
                    </p>
                    <p className="article-section__p">
                        Наша головна мета на кожній виставці – це довготривала і взаємовигідна співпраця. І завдяки Міжнародному промисловому форумі ми в
                        черговий раз здобули відмінних партнерів, яким гарантуємо високу якість інструменту і професійний сервіс.
                    </p>
                    <p className="article-section__p">Дякуємо кожному, хто приділив час нашому стенду! До нової зустрічі!</p>
                </div>

                <div className="article-section__img">
                    <img src={ newsImg2 } alt="Міжнародний промисловий форум" />
                </div>
            </section>

            <a href="/" className="article-section__back-link">
                <button className="article-section__back-link-btn">
                    <img src={ arrow } alt="arrow" />
                </button>
                повернутись до всіх новин
            </a>
        </ArticlePage>
    )
}

export default NewsArticle;

