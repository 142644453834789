import React from 'react';
import CommentsData from "./СommentsData";
import CommentItem from "./CommentItem";

const CommentsList = props => {

    return (
        <div className="comments__list">
            {CommentsData.map( item => (
                    <CommentItem { ...item } />
            ))}
        </div>
    )
}

export default CommentsList;

