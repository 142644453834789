import React, { useState } from 'react';
import logo from '../../../../img/header/logo.png';
import searchIco from '../../../../img/header/search.svg';
import phoneIco from '../../../../img/header/phone.svg';
import arrowDown from '../../../../img/arrow-down-primary.svg';
import markerIco from '../../../../img/header/marker.svg';
import profileIco from '../../../../img/header/profile-ico.svg';
import lockIco from '../../../../img/header/lock.svg';
import cartIco from '../../../../img/header/cart.svg';

const HeaderTop = props => {
    const phoneNumbers = [
      {
          phoneLink: '+1234567890',
          phoneText: '+38 (044) 587 78 38'
      },
      {
          phoneLink: '+1234565555',
          phoneText: '+38 (044) 587 55 55'
      },
      {
          phoneLink: '+1234566666',
          phoneText: '+38 (044) 587 66 66'
      },
    ];

    const languages = [
        {
            key: 'uk',
            title: "укр"
        },
        {
            key: 'ru',
            title: "рус"
        }
    ];

    const [ activeLanguage, setActiveLanguage ] = useState("uk" );

    const toggleLang = ( language ) => {
        setActiveLanguage( language );
    };

  return (
      <div className="header__top-wrap">
          <div className="header__top container">
              <a href="/" className="header__logo">
                  <img src={ logo } alt="Вектортул" className="logo" />
              </a>

              <div className="header__search">
                  <input type="text" className="header__search-input" placeholder="Пошук..." />
                  <div className="header__search-ico">
                      <img src={ searchIco } alt="search" />
                  </div>
              </div>


              <div className="header__contacts">
                  <div className="header__contacts-top">
                      <a href={ phoneNumbers[0].phoneLink } className="header__contacts-call-link">
                          <div className="header__contacts-call-ico">
                              <img src={ phoneIco } alt="phone" />
                          </div>
                          <div className="header__contacts-phone">{ phoneNumbers[0].phoneText }</div>
                      </a>
                      <div className="header__contacts-arrow">
                          <img src={ arrowDown } alt="arrow" />
                      </div>
                  </div>
                  <ul className="header__contacts-dropdown">
                      <li className="header__contacts-dropdown-item">{ phoneNumbers[1].phoneText }</li>
                      <li className="header__contacts-dropdown-item">{ phoneNumbers[2].phoneText }</li>
                  </ul>
              </div>

              <div className="header__address">
                  <img src={ markerIco } alt="location" />
                  м. Київ, вул. Борщагівська, 145
              </div>

              <div className="header__lang">
                  {languages.map( lang => (
                      <div
                          className={`header__lang-item ${ lang.key === activeLanguage ? " active" : '' }`}
                          onClick={() => toggleLang( lang.key ) }
                      >
                          { lang.title }
                      </div>
                  ))}
              </div>

              <div className="header__features">

                  <div className="header__feature header__feature--profile">
                      <div className="header__feature-ico-wrap">
                          <img className="header__feature-ico" src={ profileIco } alt="profile" />
                          <div className="header__feature-status">
                              <img src={ lockIco } alt="lock" />
                          </div>
                      </div>
                  </div>

                  <div className="header__feature header__feature--basket">
                      <div className="header__feature-ico-wrap">
                          <img className="header__feature-ico" src={ cartIco } alt="cart" />
                          <div className="header__feature-status">
                              2
                          </div>
                      </div>
                  </div>

              </div>
          </div>

      </div>
  )
}

export default HeaderTop;
