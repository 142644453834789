import React from 'react';
import './FormSection.scss';
import bgImage from '../../../img/callback-form-bg.jpg';
import bgImageMailing from '../../../img/mailing-form-bg.jpg';

const FormSection = props => {
  return (
    <section className={`form-section${ props.mailing ? " mailing" : ''}`}>

        <div className="form-section__bg">
            <img src={ props.mailing ? bgImageMailing : bgImage } alt={ props.mailing ? "mailing" : "callback" } />
        </div>

        <div className="form-section__body container">
            <div className="form-section__title-wrap">
                {
                    props.mailing ?
                        <>
                            <h3 className="form-section__title">Бажаєте отримувати більше цікавої та користної
                                інформації?</h3>
                            <p className="form-section__subtitle">Залиште свою електронну адресу, щоб нічого не
                                пропустити</p>
                        </>
                        :
                        <>
                            <h3 className="form-section__title">потрібна консультація?</h3>
                            <p className="form-section__subtitle">Замовте зворотній дзвінок і наші спеціалісти зв’яжуться з вами</p>
                        </>
                };
            </div>

            <form action="/" className="form-section__form">
                {
                    props.mailing ?
                        <>
                            <input className="form-section__input" type="email" name="email" placeholder="Ваш e-mail" />
                            <button className="form-section__submit-btn" type="submit">надіслати</button>
                        </>
                        :
                        <>
                            <input className="form-section__input" type="name" name="name" placeholder="Ваше ім’я" />
                            <input className="form-section__input" type="tel" name="tel" placeholder="+38 (0__) ___-__-__" />
                            <button className="form-section__submit-btn" type="submit">Замовити дзвінок</button>
                        </>
                };

            </form>
        </div>
    </section>
  )
}

export default FormSection;
