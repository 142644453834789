import newsImg1 from '../../img/news/news-item-img1.jpg';
import newsImg2 from '../../img/news/news-item-img2.jpg';
import newsImg3 from '../../img/news/news-item-img3.jpg';
import newsImg4 from '../../img/news/news-item-img4.jpg';
import newsImg5 from '../../img/news/news-item-img5.jpg';
import newsImg6 from '../../img/news/news-item-img6.jpg';

const NewsData = [
    {
        type: 'news',
        img: newsImg1,
        date: '27 листопада 2020',
        commentsCount: '2',
        title: 'МІЖНАРОДНИЙ ПРОМИСЛОВИЙ ФОРУМ-2020 | КИЇВ',
        subtitle: 'За традицією, компанія «ВЕКТОРТУЛ» взяла участь в щорічній і довгоочікуваної виставці «Міжнародний промисловий форум-2020». Незважаючи на складності сучасного світу, ми виставці «Міжнародний промисловий форум-2020». Незважаючи на складності сучасного світу, ми...',
        id: 1
    },
    {
        type: 'news',
        img: newsImg2,
        date: '11 вересня 2020',
        commentsCount: '0',
        title: 'ЗАПОРІЗЬКИЙ ПРОМИСЛОВИЙ ФОРУМ-2020 | запоріжжя',
        subtitle: 'Незважаючи на умови сучасного світу, а саме пандемію, ми все-таки змогли взяти участь у виставці. Дотримуючись всіх санітарних заходів, ми вітали всіх відвідувачів біля стенду...',
        id: 2
    },
    {
        type: 'news',
        img: newsImg3,
        date: '22 листопада 2019',
        commentsCount: '1',
        title: 'МІЖНАРОДНИЙ ПРОМИСЛОВИЙ ФОРУМ-2019 | КИЇВ',
        subtitle: 'І вже за доброю і сформованою традицією, компанія «ВЕКТОРТУЛ» взяла участь. Щороку ми представляємо все більше цікавих рішень і новинок в сфері листообробки. Як представляємо все більше цікавих рішень і новинок в сфері листообробки. Як',
        id: 3
    },
    {
        type: 'news',
        img: newsImg4,
        date: '23 травня 2019',
        commentsCount: '2',
        title: 'ЗАПОРІЗЬКИЙ ПРОМИСЛОВИЙ ФОРУМ-2019 | запоріжжя',
        subtitle: 'Щороку компанія «ВЕКТОРТУЛ» із задоволенням відвідує місто Запоріжжя для участі у виставці. І щороку ми знаходимо чим здивувати кожного відвідувача Запорізького промислового',
        id: 4
    },
    {
        type: 'news',
        img: newsImg5,
        date: '11 квітня 2019',
        commentsCount: '2',
        title: 'МЕТАЛ ОБЛАДНАННЯ ІНСТРУМЕНТ-2019 | ЛЬВІВ',
        subtitle: 'Львівська виставка, власне як і кожна, в яких ВЕКТОРТУЛ бере участь – це відмінний привід бути ближче до наших дорогих партнерам, де ми можемо поспілкуватися і надати',
        id: 5
    },
    {
        type: 'news',
        img: newsImg6,
        date: '01 березня 2019',
        commentsCount: '3',
        title: 'KHARKIV PROM DAYS-2019 | харків',
        subtitle: 'Кожна обласна виставка дає чудову можливість бути ближче до наших дорогих партнерів, де ми можемо весь день спілкуватися і консультувати з питань підвищення продуктивності їх...',
        id: 6
    },
];

export default NewsData;