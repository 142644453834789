import React from 'react';
import MainBenefitsData from './MainBenefitsData';
import MainBenefitsItem from './MainBenefitsItem';


const MainBenefitsItemsList = props => {
    return (
        <div className="benefits__body container">
            {MainBenefitsData.map(item => (
            <MainBenefitsItem key={ item.id } { ...item } />
            ))}
        </div>
    )
}

export default MainBenefitsItemsList;