const HeaderNavData = [
    {
        name: 'блог',
        active: false
    },
    {
        name: 'новини',
        active: false
    },
    {
        name: 'відео',
        active: false
    },
    {
        name: 'компанія',
        active: false
    },
    {
        name: 'Контакти',
        active: false
    },
]

export default HeaderNavData;
