import React from 'react';
import MainIntro from "./MainIntro";
import MainProduction from "./MainProduction";
import MainResults from "./MainResults";
import MainWhyUs from "./MainWhyUs";
import MainBenefits from "./MainBenefits";
import MainPopularProducts from "./MainPopularProducts";
import CallbackForm from "../globalComponents/CallbackForm";

const Main = () => {
  return (
    <main>
      <MainIntro />
      <MainProduction />
      <MainResults />
      <MainWhyUs />
      <MainBenefits />
      <MainPopularProducts />
      <CallbackForm />
    </main>
  )
}

export default Main;
