import React from 'react';
import ArticlePage from "../globalComponents/ArticlePage";
import articleBg from "../../img/articles/blog-article-bg.jpg";
import blogImg1 from "../../img/articles/blog-article-img-1.jpg";
import blogImg2 from "../../img/articles/blog-article-img-2.jpg";
import blogImg3 from "../../img/articles/blog-article-img-3.jpg";
import blogImg4 from "../../img/articles/blog-article-img-4.jpg";
import blogImg5 from "../../img/articles/blog-article-img-5.jpg";
import arrow from "../../img/arrow-right-white.svg";

const BlogArticle = props => {
    const blogArticleData = {
        bg: articleBg,
        category: 'blog',
        title: 'Технічні рекомендації по шліфовці пуансонів і матриць',
        date: '24 грудня 2020',
        commentsCount: '2'
    };

    return (
        <ArticlePage { ...blogArticleData } >
            <section className="article-section article-section--column">
                <p className="article-section__p">
                    Як говорилося раніше, на довговічність пуансонів і матриць впливає своєчасне шліфування.
                </p>
                <p className="article-section__p">
                    Крім того, величезний вплив на стійкість інструментів після заточки є правильно підібраний режим шліфування. Часто на виробництвах стикаються з проблемами вже після першого перешліфування. А саме:
                </p>
                <ul className="article-section__list">
                    <li className="article-section__list-item">зменшення зносостійкості;</li>
                    <li className="article-section__list-item">утворення відколів на краях інструментів в процесі роботи;</li>
                    <li className="article-section__list-item">поломка як пуансонів, так і матриць</li>
                </ul>
                <p className="article-section__p article-section__p--highlight">
                    Як цього уникнути? Як шліфувати інструмент, щоб повертати інструмент до заводської якості?
                </p>
                <p className="article-section__p">
                    Розглянемо більш докладно.
                </p>
                <p className="article-section__p">
                    Як правило, на виробництві шліфують пуансони і матриці на 2-х видах шліфувальних верстатів:
                </p>
            </section>
            <section className="article-section article-section--figure">
                <figure className="article-section__figure">
                    <img src={ blogImg1 } alt="Універсальний плоскошліфувальний верстат" />
                    <figcaption className="article-section__figcaption">Універсальний плоскошліфувальний верстат</figcaption>
                </figure>
                <figure className="article-section__figure">
                    <img src={ blogImg2 } alt="Універсальний плоскошліфувальний верстат" />
                    <figcaption className="article-section__figcaption">Спеціалізований автоматичний заточний верстат для інструменту до координатно-пробивних пресів</figcaption>
                </figure>
            </section>

            <section className="article-section article-section--column">
                <p className="article-section__p">Часто низька якість шліфування трапляється на плоскошліфувальних верстатах.</p>
                <p className="article-section__p">Якість шліфування на плоскошліфувальних верстатах (а) залежить від багатьох чинників:</p>
                <ul className="article-section__list">
                    <li className="article-section__list-item">ключова роль – кваліфікація фахівця, який зможе правильно налаштувати режим шліфування;</li>
                    <li className="article-section__list-item">стан обладнання (тому що часто використовується обладнання зроблено ще в СРСР і має технічний знос);</li>
                    <li className="article-section__list-item">вибір шліфувального круга;</li>
                    <li className="article-section__list-item">стан шліфувального круга;</li>
                    <li className="article-section__list-item">правильно підібрана МОР.</li>
                </ul>
                <p className="article-section__p">Так виглядає інструмент після неправильного перешліфування:</p>
            </section>
            <section className="article-section article-section--column">
                <div className="article-section__img">
                    <img src={ blogImg3 } alt="demonstration" />
                </div>
            </section>
            <section className="article-section article-section--column">

                <p className="article-section__p">
                    Як видно на фото, є безліч чорних хвилястих плям. Це свідчить про те, що був локальний перегрів
                    і інструмент перепалений під час шліфування. Як результат, в кращому випадку, така матриця буде швидше зношуватися.
                    У гіршому варіанті утворюються відколи на ріжучої кромці або утворюється тріщина / руйнування.
                    Відновити таку поверхневу структуру, на жаль, не можна.
                </p>

                <p className="article-section__p article-section__p--highlight">Причини перепала інструменту</p>

                <ul className="article-section__list">
                    <p className="article-section__list-label">Основними причинами перепала інструменту під час шліфування можуть бути:</p>
                    <li className="article-section__list-item">занадто великий хід подачі шліфувального круга;</li>
                    <li className="article-section__list-item">неправильний режим швидкостей поперечної подачі;</li>
                    <li className="article-section__list-item">недостатня кількість МОР при шліфуванні;</li>
                    <li className="article-section__list-item">неправильно підібраний камінь;</li>
                    <li className="article-section__list-item">неякісна МОР.</li>
                </ul>

                <p className="article-section__p article-section__p--highlight">Рішення:</p>

            </section>

            <section className="article-section article-section--column">
                <div className="article-section__img">
                    <img src={ blogImg4 } alt="demonstration" />
                </div>
            </section>

            <section className="article-section article-section--column">
                <ul className="article-section__list">
                    <p className="article-section__list-label">Основними причинами перепала інструменту під час шліфування можуть бути:</p>
                    <li className="article-section__list-item">
                        Вертикальна подача за один прохід повинна складати не більше 0,03 – 0,05 мм.
                        При більшому кроці буде відбуватися локальний перегрів, що призведе до зміни поверхневої структури.
                    </li>
                    <li className="article-section__list-item">
                        Максимальне завантаження каменю 2/3 в поперечної подачі.
                    </li>
                    <li className="article-section__list-item">
                        Багато МОР не буває. Направте достатню подачу МОР безпосередньо в зону
                        контакту шліфувального каменю з інструментом. Також важлива якісна і спеціалізована МОР.
                    </li>
                    <li className="article-section__list-item">
                        У шліфувальному крузі для заточування пуансонів і матриць, абразивним матеріалом повинен бути оксид алюмінію. Такі шліфувальні диски
                        призначені для шліфування високолегованих і швидкоріжучих сталей. Також необхідно регулярно поправляти шліфувальний камінь.
                    </li>
                </ul>
                <p className="article-section__p article-section__p--highlight">Автоматичний заточний верстат</p>
                <p className="article-section__p">
                    Неякісну шліфовку легко уникнути, використовуючи автоматичний заточний верстат. Для нього не потрібно кваліфікований
                    фахівець по шліфовці. Якість шліфування не буде залежати від стану, настрою та ін. шліфувальника. Високоякісну заточку зможе зробити оператор
                    координатно-пробивного преса. Як правило, сучасні верстати обладнані ЧПУ. Досить тільки ввести дані, тобто яку
                    величину металу необхідно зняти з інструмента і натиснути START. Далі верстат все робить сам. Якість шліфування див. нижче.
                </p>
            </section>

            <section className="article-section article-section--column">
                <div className="article-section__img">
                    <img src={ blogImg4 } alt="demonstration" />
                </div>
            </section>

            <section className="article-section article-section--column">
                <p className="article-section__p">
                    Зносостійкість пуансонів і матриць після такого перешліфування буде
                    такою ж як у нового інструменту. Відеороботи автоматичного заточного верстата можна
                    подивитися <a href="/" className='article-section__link'>тут</a>.
                </p>
                <p className="article-section__p">
                    При наявності питань звертайтесь за
                    тел: <a href="tel:+380662031489" className='article-section__link'> (066) 203-14-89</a>.
                </p>
                <p className="article-section__author">Віктор Сліпченко</p>
            </section>
            <a href="/" className="article-section__back-link">
                <button className="article-section__back-link-btn">
                    <img src={ arrow } alt="arrow" />
                </button>
                повернутись до публікацій
            </a>
        </ArticlePage>
    )
}

export default BlogArticle;
