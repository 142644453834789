import React from 'react';
import MainPopularProductsSlider from "./MainPopularProductsSlider";
import './MainPopularProducts.scss';

const MainPopularProducts = props => {
  return (
    <section className="popular-products container">
            <h2 className="popular-products__title section-title">популярні товари</h2>
            <p className="popular-products__subtitle">Товари, які мають найбільший попит у наших клієнтів</p>
            <MainPopularProductsSlider />
    </section>
  )
}

export default MainPopularProducts;
