import React from 'react';

const MainResultsItem = props => {
    return (
        <div className="result-item-wrap">
            <div className="result-item">
                <div className="result-item__label">{ props.label }</div>
                <div className="result-item__value">{ props.value }</div>
            </div>
        </div>
    )
}

export default MainResultsItem;