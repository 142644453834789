import React from 'react';
import BreadCrumbs from "../globalComponents/BreadCrumbs";
import BlogList from "./BlogList";
import './Blog.scss';
import Pagination from "../globalComponents/Pagination";
import FormSection from "../globalComponents/FormSection";

const Blog = () => {
  return (
    <main>
    <BreadCrumbs />
    <div className="container">
        <h1 className="page-title">блог</h1>
        <BlogList />
        <Pagination />
    </div>
    <FormSection mailing={ true } />
    </main>
  )
}

export default Blog;
