import img1 from '../../../img/catalog/category-1.jpg';
import img2 from '../../../img/catalog/category-2.jpg';

const CatalogSubCategoriesData = [
    {
        img: img1,
        title: 'Amada Promecam',
        subcategory: [
            {
                name: 'Пуансон Amada Promecam',
                link: '/'
            },
            {
                name: 'Матриця Amada Promecam',
                link: '/'
            },
            {
                name: 'Одноручова матриця Amada Promecam',
                link: '/'
            },
            {
                name: 'Дворучова матриця Amada Promecam ',
                link: '/'
            },
        ],
        id: 1
    },
    {
        img: img2,
        title: 'Trumpf',
        subcategory: [
            {
                name: 'Пуансон Trumpf Style',
                link: '/'
            },
            {
                name: 'Матриця Trumpf Style',
                link: '/'
            },
        ],
        id: 2
    },
    {
        img: img1,
        title: 'Bystronic',
        subcategory: [
            {
                name: 'Пуансон Bystronic',
                link: '/'
            },
            {
                name: 'Матриця Bystronic',
                link: '/'
            }
        ],
        id: 3
    },
    {
        img: img1,
        title: 'Технічні рішення гнуття',
        subcategory: [
            {
                name: 'Плющення суцільне',
                link: '/'
            },
            {
                name: 'Плющення з підпружиненою матрицею',
                link: '/'
            },
            {
                name: 'Плющення з пневматичною матрицею',
                link: '/'
            },
            {
                name: 'Радіусне гнуття',
                link: '/'
            },
            {
                name: 'Z-образне гнуття',
                link: '/'
            },
            {
                name: 'Гнуття квадратної труби',
                link: '/'
            },
            {
                name: 'Рухомі чобітки',
                link: '/'
            },

        ],
        id: 4
    },
    {
        img: img1,
        title: 'Гнуття без подряпин',
        subcategory: [
            {
                name: 'Поліуретанова матриця (м’який поліуретан)',
                link: '/'
            },
            {
                name: 'Поліуретанова матриця (твердий поліуретан)',
                link: '/'
            },
            {
                name: 'Unibend',
                link: '/'
            },
            {
                name: 'Роликова матриця',
                link: '/'
            },
            {
                name: 'Поліуретанова стрічка',
                link: '/'
            },
        ],
        id: 5
    },
    {
        img: img1,
        title: 'Спеціальні профілі',
        id: 6
    },
];

export default CatalogSubCategoriesData;