import React from 'react';

const CatalogCategoriesItem = props => {

    const groupArray = (items, n) => items.reduce((acc, x, i) => {
        const idx = Math.floor(i / n);
        acc[idx] = [...(acc[idx] || []), x];
        return acc;
    }, []);


    return (
        <div className="catalog-category">
            <div className="catalog-category__img">
                <img src={ props.img } alt={ props.title } />
            </div>
            <div className="catalog-category__body">
                <a href="/" className="catalog-category__title">{ props.title }</a>
                {
                    props.subcategory ?
                        <div className="catalog-category__subcategories">

                            {
                                groupArray(props.subcategory, 4).map( children => (
                                    <div className="catalog-category__subcategories-column">
                                        {children.map(item => (
                                            <a className="catalog-category__subcategory-link" href={ item.link }>{ item.name }</a>
                                        ))}
                                    </div>
                                ))
                            }
                        </div>
                        :
                        ''
                }

            </div>
        </div>
    )
}

export default CatalogCategoriesItem;

