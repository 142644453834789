const MainBenefitsData = [
    {
        number: '01',
        title: 'Спеціалізація',
        subtitle: '«ВЕКТОРТУЛ» пропонує пробивний інструмент, штампувальний інструмент і інструмент для гнуття для більшості координатно-пробивних пресів і листозгинів відомих виробників, представлених в Україні: TRUMPF, АМАDA, PRIMA-POWER, EUROMAC, YAWEI, TAILIFT, DURMA, BAYKAL та інших. Усі постачальники-виробники мають бездоганну репутацію та надають продукцію Premium-класу.',
        id: 1
    },
    {
        number: '02',
        title: 'Співпраця',
        subtitle: 'Багаторічна і взаємовигідна співпраця — пріоритет №1 для компанії «ВЕКТОРТУЛ». Саме тому ми враховуємо всі побажання клієнтів, пропонуючи їм найбільш інноваційні рішення. Гарантуємо 100% задоволення клієнтів, які до нас звертаються.Шукаєте довгострокового партнера? Давайте працювати разом!',
        id: 2
    },
    {
        number: '03',
        title: 'Експерти в листообробці',
        subtitle: 'На чолі з Віктором Сліпченко в компанії працює команда інженерів, яка пропонує найпередовіші світові технології у сфері листообробки. Знаючи всі тонкощі роботи касетних, револьверних та мультитульних пресів, ми професійно надаємо оптимальні технічні рішення.',
        id: 3
    },
];

export default MainBenefitsData;