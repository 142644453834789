import React from 'react';
import './Footer.scss';
import logo from '../../../img/footer/footer-logo.png';
import telIco from '../../../img/header/phone.svg';
import vodaphoneIco from '../../../img/footer/vodaphone.svg';
import mailIco from '../../../img/footer/mail-ico.svg';
import markIco from '../../../img/footer/mark.svg';
import instagramIco from '../../../img/footer/instagram.svg';
import facebookIco from '../../../img/footer/facebook.svg';
import youtubeIco from '../../../img/footer/youtube.svg';
import arrow from '../../../img/arrow-right-white.svg';

const Footer = props => {


    return (
        <footer className="footer">
            <div className="footer__body container">

                <div className="footer__сolumn footer__сolumn--logo">
                    <a href='/' className="footer__logo">
                        <img src={ logo } alt="Вектортул. Експерти в листообробці"/>
                    </a>
                        <p className="footer__info-item">ТОВ «ВЕКТОРТУЛ»</p>
                        <p className="footer__info-item">Код ЄДРПОУ: 41653107.</p>
                        <p className="footer__info-item">Юридична адреса: Україна, 03062, </p>
                        <p className="footer__info-item">м. Київ, провулок Кулібіна, 3.</p>
                </div>

                <nav className="footer__сolumn footer__сolumn--nav">
                    <ul className="footer__nav">
                        <li className="footer__nav-item">
                            <a href="/" className="footer__nav-link">Блог</a>
                        </li>
                        <li className="footer__nav-item">
                            <a href="/" className="footer__nav-link">Новини</a>
                        </li>
                        <li className="footer__nav-item">
                            <a href="/" className="footer__nav-link">Відео</a>
                        </li>
                        <li className="footer__nav-item">
                            <a href="/" className="footer__nav-link">Компанія</a>
                        </li>
                        <li className="footer__nav-item">
                            <a href="/" className="footer__nav-link">Контакти</a>
                        </li>
                        <li className="footer__nav-item">
                            <a href="/" className="footer__nav-link">Доставка та оплата</a>
                        </li>
                    </ul>
                </nav>

                <div className="footer__сolumn footer__сolumn--contacts">
                    <h5 className="footer__title">Контакти</h5>
                    <ul className="footer__contacts">
                        <li className="footer__contact-item">
                            <a href="tel:+380445877838" className="footer__contact-link phone">
                                <img src={ telIco } alt="tel" />
                                +38 (044) 587 78 38
                            </a>
                        </li>
                        <li className="footer__contact-item">
                            <a href="tel:+380662031489" className="footer__contact-link phone">
                                <img src={ vodaphoneIco } alt="tel" />
                                +38 (066) 203 14 89
                            </a>
                        </li>
                        <li className="footer__contact-item">
                            <a href="tel:+380503415021" className="footer__contact-link phone">
                                <img src={ vodaphoneIco } alt="tel" />
                                +38 (050) 341 50 21
                            </a>
                        </li>
                        <li className="footer__contact-item">
                            <a href="mailto:info@vectortool.com.ua" className="footer__contact-link">
                                <img src={ mailIco } alt="tel" />
                                info@vectortool.com.ua
                            </a>
                        </li>
                        <li className="footer__contact-item">
                            <a href="/" className="footer__contact-link">
                                <img src={ markIco } alt="tel" />
                                м. Київ, вул. Борщагівська, 145
                            </a>
                        </li>
                    </ul>
                    <div className="footer__social-links">
                        <a href="/" className="footer__social-link">
                            <img src={ instagramIco } alt="instagram"/>
                        </a>
                        <a href="/" className="footer__social-link">
                            <img src={ facebookIco } alt="facebook"/>
                        </a>
                        <a href="/" className="footer__social-link">
                            <img src={ youtubeIco } alt="youtube"/>
                        </a>
                    </div>
                </div>

                <div className="footer__сolumn footer__сolumn--schedule">
                    <h5 className="footer__title">Графік роботи</h5>
                    <div className="footer__schedule">
                        <div className="footer__schedule-item">
                            <span className="footer__schedule-label">Пн-Пт:</span>
                            <span className="footer__schedule-value"> 8:00 – 17:00</span>
                        </div>
                        <div className="footer__schedule-item">
                            <span className="footer__schedule-label">Сб-Вс:</span>
                            <span className="footer__schedule-value"> за домовленістю</span>
                        </div>
                    </div>
                </div>

                <div className="footer__subscribe">
                    <p className="footer__subscribe-label">Підпишіться на нашу розсилку, щоб бути
                        в курсі актуальних новин та акцій
                    </p>
                    <form className="footer__subscribe-form">
                        <input className="footer__subscribe-input" type="email" name="email" placeholder="Ваш e-mail" />
                        <button className="footer__subscribe-confirm" type="submit">
                            <img src={ arrow } alt="arrow" />
                        </button>
                    </form>
                </div>

                <button className="footer__scroll-top-btn">
                    <img src={ arrow } alt="arrow" />
                </button>

            </div>

            <div className="footer__bottom-section">
                <div className="footer__legal-info-wrap container">
                    <p className="footer__legal-info">
                        <span className="bold-text">ПРАВОВА ІНФОРМАЦІЯ. </span> Hypertherm®, Kjellberg®, Thermal Dynamics®, ESAB®, Lincoln Electric®, Bystronic®, Pricetec®, Trumpf®, Raytools®, KOIKE®, Amada®, DNE®, Lasermech®, BLM®, Prima Power®, Salvagnini®, WSX®, HighYag®, Mate, Wilson  – зареєстровані торгові марки. Всі торгові марки, наведені на сайті, є власністю відповідних компаній, і надані лише для довідок. ВЕКТОРТУЛ жодним чином не пов'язана з вищеназваними виробниками і зареєстрованими ними товарними знаками. Марки машин, деталі, описи і номера моделей згадуються тут виключно для зручності перевірки сумісності. Всі деталі зроблені під маркою ВЕКТОРТУЛ або для ВЕКТОРТУЛ. У їх виробництві не бере участь ні один із зазначених виробників, якщо це не вказано явно.
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
