import React from 'react';
import MainWhyUsData from './MainWhyUsData';
import MainWhyUsItem from './MainWhyUsItem';


const MainWhyUsItemsList = props => {
    return (
        <div className="why-us__body">
            {MainWhyUsData.map(item => (
            <MainWhyUsItem key={ item.id } { ...item } />
            ))}
        </div>
    )
}

export default MainWhyUsItemsList;