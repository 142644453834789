import React from 'react';
import CatalogNavList from "./CatalogNavList";

const CatalogNav = props => {

    return (
        <nav className="catalog__nav-wrap">
            <ul className="catalog__nav">
                <CatalogNavList />

                {
                    props.inCategory ?
                        <a href="/" className="catalog__download-link">
                            Завантажити каталог
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.25 14.25H15.75V15.75H2.25V14.25ZM9.75 9.879L14.3032 5.325L15.3638 6.3855L9 12.75L2.63625 6.38625L3.69675 5.325L8.25 9.8775V1.5H9.75V9.879Z" fill="white"/>
                            </svg>
                        </a>
                        :
                        ''
                }
            </ul>
        </nav>
    )
}

export default CatalogNav;

