import React from 'react';
import CatalogNavData from "./CatalogNavData";
import CatalogNavItem from "./CatalogNavItem";


const CatalogNavList = props => {

    return (
        CatalogNavData.map( item => (
            <CatalogNavItem { ...item } />
        ))
    )

}

export default CatalogNavList;


