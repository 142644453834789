import React from 'react';


const MainIntroItem = props => {
    return (
        <div className="intro__item-wrap">
            <div className="intro__item">
                <div className="intro__item-img">
                    <img src={ props.img } alt={ props.title } />
                </div>
                <video className='intro__video' autoPlay loop muted>
                    <source src={ props.video } type='video/mp4' />
                </video>
                <div className="intro__item-description">
                    <h3 className="intro__item-title"> { props.title } </h3>
                    <p className="intro__item-subtitle"> { props.subtitle } </p>
                    <a href="/" className="intro__to-catalog">у каталог</a>
                </div>
            </div>
        </div>
    )
}

export default MainIntroItem;