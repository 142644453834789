import React from 'react';
import CatalogCategoriesData from "./CatalogCategoriesData";
import CatalogCategoriesItem from "./CatalogCategoriesItem";
import CatalogSubCategoriesData from "./CatalogSubCategoriesData";


const CatalogCategoriesList = props => {

    return (
        CatalogSubCategoriesData.map(item => (
            <CatalogCategoriesItem { ...item } />
        ))
    )

}

export default CatalogCategoriesList;


