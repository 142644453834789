import React from 'react';
import MainResultsData from './MainResultsData';
import MainResultsItem from './MainResultsItem';


const MainResultsItemsList = props => {
    return (
        <div className="results__body">
            {MainResultsData.map(item => (
            <MainResultsItem key={ item.id } { ...item } />
            ))}
        </div>
    )
}

export default MainResultsItemsList;