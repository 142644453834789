import React from 'react';
import './Pagination.scss';
import arrow from '../../../img/arrow-orange.svg';

const Pagination = props => {
    return (
        <div className="pagination">
            <div className="pagination__arrow disabled">
                <img src={ arrow } alt="arrow" />
            </div>
            <div className="pagination__item active">1</div>
            <div className="pagination__item">2</div>
            <div className="pagination__arrow">
                <img src={ arrow } alt="arrow" />
            </div>
        </div>
    )
};


export default Pagination;