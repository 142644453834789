import React from 'react';

const CommentItem = props => {

    return (
        <div className="comment">
            <div className="comment__header">
                <div className="comment__author">{ props.author }</div>
                <div className="comment__date">{ props.date }</div>
            </div>
            <p className="comment__comment">{ props.comment }</p>
            {props.feedback ?
                <div className="comment__feedback">
                    <div className="comment__header">
                        <div className="comment__author">{props.feedback.author}</div>
                        <div className="comment__date">{props.feedback.date}</div>
                    </div>
                    <p className="comment__comment">{props.feedback.comment}</p>
                </div>
                :
                ''
            }
        </div>
    )
}

export default CommentItem;
