import speedIco from  '../../../img/main-page/why-us/flash.svg';
import presentIco from  '../../../img/main-page/why-us/present.svg';
import guaranteeIco from  '../../../img/main-page/why-us/guarantee.svg';
import deliveryIco from  '../../../img/main-page/why-us/airplane.svg';
import qualityIco from  '../../../img/main-page/why-us/quality.svg';
import serviceIco from  '../../../img/main-page/why-us/service.svg';
import cardBg from '../../../img/main-page/why-us/item-bg.jpg';


const MainWhyUsData = [
    {
        ico: speedIco,
        title: 'швидкість роботи',
        subtitle: 'Наші інженери дають відповідь у день замовлення та завжди готові надати технічну консультацію.',
        bgImg: cardBg,
        id: 1
    },
    {
        ico: presentIco,
        title: 'ПРОГРАМА ЛОЯЛЬНОСТІ',
        subtitle: 'Наш головний пріоритет — довгострокова співпраця. Тому ми надаємо постійним клієнтам цікаві та вигідні умови.',
        bgImg: cardBg,
        id: 2
    },
    {
        ico: guaranteeIco,
        title: 'гарантія',
        subtitle: 'Ми надаємо гарантію 1 рік на каністри, направляючі, перехідники, адаптери, а також на інший інструмент.',
        bgImg: cardBg,
        id: 3
    },
    {
        ico: deliveryIco,
        title: 'швидка доставка',
        subtitle: 'Завдяки співпраці з найкращими світовими авіаперевізниками та автоперевізниками, ми максимально швидко доставимо Ваш інструмент.',
        bgImg: cardBg,
        id: 4
    },
    {
        ico: qualityIco,
        title: 'Висока якість',
        subtitle: 'Висока і стабільна якість Ketec за рахунок впровадження у виробництві контролю якості на кожному етапі. Це дозволило їм отримати сертифікат ISO 9000:2000 та ISO 9001:2008.',
        bgImg: cardBg,
        id: 5
    },
    {
        ico: serviceIco,
        title: 'професійний сервіс ',
        subtitle: 'Ми пропонуємо технічну підтримку як оnline, так і безпосередньо при відвідуванні виробництв.',
        bgImg: cardBg,
        id: 6
    }
];

export default MainWhyUsData;