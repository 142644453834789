import React from 'react';
import MainWhyUsItemsList from "./MainWhyUsItemsList";
import './MainWhyUs.scss';

const MainWhyUs = props => {
  return (
    <section className="why-us container">
            <h2 className="why-us__title section-title">чому саме ми?</h2>
            <p className="why-us__subtitle">Ми знаходимо індивідуальний підхід до кожного клієнта</p>
            <MainWhyUsItemsList />
    </section>
  )
}

export default MainWhyUs;
