const CommentsData = [
    {
        author: 'Владислав Гондопас',
        date: '7.01.2021, 4:05',
        comment: 'Підкажіть, будь ласка, коли саме у 2021 буде наступний міжнародний форум?',
        feedback: {
            author: 'Адміністратор',
            time: '7.01.2021, 8:00',
            comment: 'Доброго дня! Форум відбудеться 16-19 листопада 2021.',
        },
        id: 1
    },
    {
        author: 'Анастасія Денисова',
        date: '1.01.2021, 16:45',
        comment: 'Дякую! Ви найкращі!',
        id: 2
    },
];

export default CommentsData;