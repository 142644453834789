import React from 'react';
import checkIco from '../../../img/check-ico.svg';

const MainPopularProductsItem = props => {
    return (
        <div className="slider-product">
            <div className="slider-product__img">
                <img src={ props.img } alt={ props.title } />
            </div>
            <div className="slider-product__bottom-wrap">
                <h4 className="slider-product__title">{ props.title }</h4>
                <div className="slider-product__location">
                    <img src={ checkIco } alt="check" />
                    { props.location }
                </div>
            </div>
            <div className="slider-product__characteristics">
                <h4 className="slider-product__title">{ props.title }</h4>
                { props.characteristics.map( item => (
                    <div className="slider-product__characteristics-item">
                        <div className="slider-product__characteristics-label">{ item.label }</div>
                        <div className="slider-product__characteristics-value">{ item.value }</div>
                    </div>
                ))}
                <button className="slider-product__view-btn">швидкий перегляд</button>
            </div>
        </div>
    )
}

export default MainPopularProductsItem;