import React from 'react';
import MainIntroData from './MainIntroData';
import MainIntroItem from './MainIntroItem';


const MainIntroItemsList = props => {
    return (
        MainIntroData.map( item => (
            <MainIntroItem key={ item.id } { ...item } />
        ))
    )
}

export default MainIntroItemsList;