import React from 'react';
import CatalogCategoriesList from "./CatalogCategoriesList";

const CatalogCategories = props => {

    return (
        <div className="catalog__categories">
                <CatalogCategoriesList />
        </div>
    )
}

export default CatalogCategories;

