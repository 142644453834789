const MainResultsData = [
    {
        label: 'Кількість доставленого інструменту:',
        value: '6 800',
        id: 1
    },
    {
        label: 'Кількість задоволених клієнтів:',
        value: '220',
        id: 2
    },
    {
        label: 'Вага доставленого інcтрументу, кг:',
        value: '6 000',
        id: 3
    },
    {
        label: 'Кількість завершених проектів:',
        value: '2 500',
        id: 4
    },
    {
        label: 'Кількість проведених форумів:',
        value: '8',
        id: 5
    },
];

export default MainResultsData;