const CatalogNavData = [
    {
        value: 'Координатно-пробивний інструмент',
        active: false,
        id: 1
    },
    {
        value: 'Інструмент для гнуття',
        active: false,
        id: 2
    },
    {
        value: 'Лінзи, сопла для лазера',
        active: false,
        id: 3
    },
    {
        value: 'Електроди, сопла для плазми',
        active: false,
        id: 4
    },
    {
        value: 'Інше',
        active: false,
        id: 5
    },
];

export default CatalogNavData;