import productImg1 from  '../../../img/main-page/products/product-img1.jpg';
import productImg2 from  '../../../img/main-page/products/product-img2.jpg';
import productImg3 from  '../../../img/main-page/products/product-img3.jpg';
import productImg4 from  '../../../img/main-page/products/product-img4.jpg';
import productImg5 from  '../../../img/main-page/products/product-img5.jpg';
import productImg6 from  '../../../img/main-page/products/product-img6.jpg';



const MainPopularProductsData = [
    {
        img: productImg1,
        title: 'Пуансон А станція TOP85 (Аналог Amada)',
        location: 'На складі в Австрії',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 1
    },
    {
        img: productImg2,
        title: 'Матриця А станція Thick Turret',
        location: 'На складі  в Україні',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 2
    },
    {
        img: productImg3,
        title: 'Пуансон С станція TOP85',
        location: 'На складі в Україні',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 3
    },
    {
        img: productImg4,
        title: 'Знімач С станція TOP85',
        location: 'На складі  в США',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 4
    },
    {
        img: productImg5,
        title: 'Станція С TOP85 в збірці (Тримач)',
        location: 'На складі  в Кореї',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 5
    },
    {
        img: productImg6,
        title: 'Матриця С станція Thick Turret',
        location: 'На складі в Австрії',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 6
    },
    {
        img: productImg6,
        title: 'Матриця С станція Thick Turret',
        location: 'На складі в Австрії',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 7
    },
    {
        img: productImg6,
        title: 'Матриця С станція Thick Turret',
        location: 'На складі в Австрії',
        characteristics: [
            {
                label: 'Макс. розмір:',
                value: '50,8 мм'
            },
            {
                label: 'Зона перешліфовки:',
                value: '3,0 мм'
            },
            {
                label: 'Профіль отвору:',
                value: 'Пісочний годинник'
            },
            {
                label: 'Оброблюючий матеріал:',
                value: 'Чорна сталь, нержавіюча сталь, алюміній'
            },
        ],
        id: 8
    },
];

export default MainPopularProductsData;