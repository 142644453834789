import productBg from '../../../img/main-page/production/product-bg.png';

const MainProductionData = [
    {
        img: productBg,
        title: 'система thick turret',
        id: 1
    },
    {
        img: productBg,
        title: 'система trumpf',
        id: 2
    },
    {
        img: productBg,
        title: 'розмагнічувач',
        id: 3
    },
    {
        img: productBg,
        title: 'інструмент для гнуття',
        id: 4
    },
    {
        img: productBg,
        title: 'компактні листозгини teda',
        id: 5
    },
    {
        img: productBg,
        title: 'заточний верстат',
        id: 6
    }
];

export default MainProductionData;