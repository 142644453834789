import productionImg1 from '../../../../img/header/production1.jpg';
import productionImg2 from '../../../../img/header/production2.jpg';
import productionImg3 from '../../../../img/header/production3.jpg';

const HeaderBottomCategoriesData = [
    {
        img: productionImg1,
        title: 'Координатно-пробивний інструмент',
        list: [
            'Thick Turret',
            'Trumpf',
            'Мультитул',
            'Формовки'
        ]
    },

    {
        img: productionImg2,
        title: 'Інструмент для гнуття',
        list: [
            'Amada Promecam',
            'Trumpf',
            'Bystronic',
            'Технічні рішення гнуття',
            'Гнуття без подряпин',
            'Спеціальні профілі'
        ]
    },

    {
        img: productionImg3,
        title: 'Лінзи, сопла для ЛАЗЕРА',
        list: [
            'Precitec',
            'Trumpf',
            'Raytools',
            'Bystronic',
            'Amada',
            'Prima-Power',
            'WSX',
            'HighYag',
            'Lasermech',
            'Salvagnini',
            'DNE',
            'BLM Group'
        ]
    },

    {
        img: productionImg1,
        title: 'електроди, сопла для ПЛАЗМИ',
        list: [
            'Hypetherm',
            'Kjellberg',
            'Thermal Dynamics',
            'ESAB',
            'Lincoln Electric',
            'KOIKE'
        ]
    },

    {
        img: productionImg3,
        title: 'інше',
        list: [
            'Ножі для гільотин',
            'Розмагнічувач',
            'Заточний верстат',
            'Компактні листозгини'
        ]
    },
]

export default HeaderBottomCategoriesData;
