import React, { useState } from 'react';
import burgerIco from '../../../../img/header/burger-ico.svg';
import arrowRight from '../../../../img/arrow-right-white.svg';
import askUsBg from '../../../../img/header/questions-bg.jpg';
import HeaderBottomCategories from "./HeaderBottomCategories";
import HeaderNavList from "./HeaderNavList";

const HeaderBottom = props => {
    const [ categoriesDropdownState, setCategoriesDropdownState ] = useState(false);
    return (
        <>
        <div className="header__bottom-wrap">
          <div className="header__bottom container">
              <button className="header__production-btn" onClick={ ()=> setCategoriesDropdownState( state => !state ) } >
                  <div className="header__burger-ico">
                      <img src={ burgerIco } alt="menu" />
                  </div>
                  продукція
              </button>
              <HeaderNavList />
              <button className="header__get-consultation-btn">
                  отримати консультацію
                  <img src={ arrowRight } alt="arrow" />
              </button>
          </div>
        </div>
        <section className={`header-production${categoriesDropdownState ? ' active' : ''}` }>

            <HeaderBottomCategories />

            <div className="header-ask-us">
                <img src={ askUsBg } alt="ask us" />
                <div className="header-ask-us__label">
                    <svg className="header-ask-us__arrows" width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 21.6119L4.10256 21.6119L10 14.9452L10 10.5862L0 21.6119ZM20.0009 21.6123L15.8983 21.6123L10.0009 14.9456L10.0009 10.5867L20.0009 21.6123Z" fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 11.0259L4.10256 11.0259L10 4.35925L10 0.000278989L0 11.0259ZM20.0009 11.0264L15.8983 11.0264L10.0009 4.3597L10.0009 0.000725309L20.0009 11.0264Z" fill="white"/>
                    </svg>
                    маєте запитання?
                </div>
            </div>
        </section>
    </>
    )

}

export default HeaderBottom;
