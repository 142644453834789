import React from 'react';
import MainResultsItemsList from "./MainResultsItemsList";
import './MainResults.scss';
import resultsBg from '../../../img/main-page/results-bg.jpg';

const MainResults = props => {
  return (
    <section className="results">
        <div className="results__bg">
            <img src={ resultsBg } alt="результати"/>
        </div>
        <div className="results__content container">
            <h2 className="results__title">результати</h2>
            <p className="results__subtitle">Наша діяльність за останній рік: </p>
            <MainResultsItemsList />
        </div>
    </section>
  )
}

export default MainResults;
