import React from 'react';
import MainBenefitsItemsList from "./MainBenefitsItemsList";
import './MainBenefits.scss';
import benefitsBg from '../../../img/main-page/benefits-bg.jpg';

const MainBenefits = props => {
  return (
    <section className="benefits">
        <div className="benefits__bg">
            <img src={ benefitsBg } alt="" />
        </div>
        <MainBenefitsItemsList />
    </section>
  )
}

export default MainBenefits;
