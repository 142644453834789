import blogImg1 from '../../img/blog/blog-item-img1.jpg';
import blogImg2 from '../../img/blog/blog-item-img2.jpg';
import blogImg3 from '../../img/blog/blog-item-img3.jpg';
import blogImg4 from '../../img/blog/blog-item-img4.jpg';

const BlogData = [
    {
        type: 'blog',
        img: blogImg1,
        title: 'Технічні рекомендації по шліфовці пуансонів і матриць',
        subtitle: 'Як говорилося раніше, на довговічність пуансонів і матриць впливає своєчасне шліфування. Крім того, величезний вплив на стійкість інструментів після заточки є правильно підібраний режим шліфування. Часто на виробництвах стикаються з проблемами вже після першого перешліфування. А саме: зменшення зносостійкості;\n' +
            'утворення відколів на краях інструментів в процесі роботи;\n' +
            'поломка як пуансонів так і матриць. Як цього уникнути? Як шліфувати інструмент, щоб...',
        id: 1
    },
    {
        type: 'blog',
        img: blogImg2,
        title: 'Як часто шліфувати пуансон, матрицю?',
        subtitle: 'Одним з найважливіших факторів збільшення терміну служби пуансонів і матриць, є своєчасне шліфування інструментів. Чим частіше заточка і менший шліфувальний шар, тим довше термін служби інструментів. Розглянемо приклад, коли ріжучу кромку не шліфувати протягом 1,0 мм, а також інший випадок якщо шліфувати кожні 0,25 мм. До отримання радіуса кромки 0,25 (Rкр = 0,25 мм) – пуансон пробиває 200’000,0 отворів. До отримання Rкр = 0,5 мм – пуансон пробиває...',
        id: 2
    },
    {
        type: 'blog',
        img: blogImg3,
        title: 'Tecnostamp – Інструмент для листозгинальних пресів',
        subtitle: 'Компанія Tecnostampє однією з найбільших авторитетних італійських компаній з виробництва, проектування і продажу інструментів для листозгинальних пресів. Tecnostamp пропонує дуже широкий асортимент своєї продукції, більш ніж 800 стандартних інструментів є в наявності на складі компанії. Щоб задовольнити всі потреби клієнта, Tecnostamp розробив виробничу лінію з виготовлення спеціальних інструментів.\n' +
            'Оскільки інструменти поставляються по всьому світу...',
        id: 3
    },
    {
        type: 'blog',
        img: blogImg4,
        title: 'Ketec – Інструмент для координатно-пробивних пресів',
        subtitle: 'Ми поставляємо пробивний і формоутворювальний інструмент KETEC для більшості відомих виробників координатно-пробивних пресів, таких як: AMADA, PRIMA POWER, LVD, Euromac, DURMA, Yawei-Nisshinbo, Tailift, Centro Line. Інструмент KETEC доступний для таких інструментальних систем як:\n' +
            'THICK TURRET, THIN TURRET, TRUMPF, PRIMA POWER MULTITOOL, EUROMAC MT, MURATA (114, VULCAN).\n' +
            'KETEC виробляє серії пробивного інструменту повністю сумісні з...',
        id: 4
    },
];

export default BlogData;