import introBg1 from '../../../img/main-page/intro-item1.jpg';
import introBg2 from '../../../img/main-page/intro-item2.jpg';
import introBg3 from '../../../img/main-page/intro-item3.jpg';
import introVideoBg1 from '../../../video/video1.mp4';
import introVideoBg2 from '../../../video/video2.mp4';
import introVideoBg3 from '../../../video/video3.mp4';


const MainIntroData = [
    {
        img: introBg1,
        video: introVideoBg1,
        title: 'система thick turret',
        subtitle: 'Інструмент для револьверних пресів типу Amada, Prima-Power, Euromac, Yawei, Tailift, Durma та ін.',
        id: 1
    },
    {
        img: introBg2,
        video: introVideoBg2,
        title: 'система trumpf',
        subtitle: 'Інструмент для револьверних пресів типу Amada, Prima-Power, Euromac, Yawei, Tailift, Durma та ін.',
        id: 2
    },
    {
        img: introBg3,
        video: introVideoBg3,
        title: 'інструмент для гнуття',
        subtitle: 'Інструмент для револьверних пресів типу Amada, Prima-Power, Euromac, Yawei, Tailift, Durma та ін.',
        id: 3
    }

];

export default MainIntroData;