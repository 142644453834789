import React from 'react';
import BreadCrumbs from "../globalComponents/BreadCrumbs";
import CatalogNav from "./CatalogNav/";
import CatalogCategories from "./CatalogCategories/";
import './Catalog.scss';
import CatalogSubCategories from "./CatalogSubCategories/CatalogSubCategories";
import CatalogFilter from "./CatalogFilter";

const Catalog = props => {

    return (
        <main>
            <div className="container">
                <BreadCrumbs />
                <h1 className="page-title">Продукція</h1>

                <section className="catalog">
                    <aside className="catalog__aside">
                        <CatalogNav inCategory={ true } />
                        {/*<CatalogFilter />*/}
                    </aside>

                    <div className="catalog__body">
                        <CatalogCategories />
                        {/*<CatalogSubCategories />*/}
                    </div>

                </section>

            </div>
        </main>
    )
}

export default Catalog;

