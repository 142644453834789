import React from 'react';

const MainBenefitsItem = props => {
    return (
        <div className="benefit-wrap">
            <div className="benefit">
                <div className="benefit__body">
                    <div className="benefit__number">{ props.number }</div>
                    <h5 className="benefit__title">{ props.title }</h5>
                    <p className="benefit__subtitle">{ props.subtitle }</p>
                </div>
            </div>
        </div>
    )
}

export default MainBenefitsItem;