import React from 'react';
import MainIntroItemsList from "./MainIntroItemsList";
import './MainIntro.scss';

const MainIntro = props => {
  return (
    <div className="intro-wrap">
        <section className="intro">
            <MainIntroItemsList />
        </section>
    </div>
  )
}

export default MainIntro;
