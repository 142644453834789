import React from 'react'
import NewsData from "./NewsData";
import ArticleItem from "../globalComponents/ArticleItem";

const NewsList = () => {
  return (
        <section className="news">
            {NewsData.map( item => (
                <ArticleItem { ...item } key={ item.id } />
            ))}
        </section>
  )
}

export default NewsList;
