import React from 'react';
import './CallbackForm.scss';
import bgImage from '../../../img/callback-form-bg.jpg';

const CallbackForm = props => {
  return (
    <section className="callback">
        <div className="callback__bg">
            <img src={bgImage} alt="callback" />
        </div>
        <div className="callback__body container">
            <div className="callback__title-wrap">
                <h3 className="callback__title">потрібна консультація?</h3>
                <p className="callback__subtitle">Замовте зворотній дзвінок і наші спеціалісти зв’яжуться з вами</p>
            </div>
            <form action="/" className="callback__form">
                <input className="callback__input" type="name" name="name" placeholder="Ваше ім’я" />
                <input className="callback__input" type="tel" name="tel" placeholder="+38 (0__) ___-__-__" />
                <button className="callback__submit-btn" type="submit">Замовити дзвінок</button>
            </form>
        </div>
    </section>
  )
}

export default CallbackForm;
