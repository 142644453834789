import React from 'react';
import MainProductionItemsList from "./MainProductionItemsList";
import './MainProduction.scss';

const MainProduction = props => {
  return (
    <section className="production container">
        <h2 className="section-title">Наша продукція</h2>
        <MainProductionItemsList />
    </section>
  )
}

export default MainProduction;
