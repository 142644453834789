import React from 'react';
import arrow from '../../../img/arrow-orange.svg';

const CatalogNavItem = props => {

    return (
        <li className={`catalog__nav-item${ props.active ? ' active' : '' }`}>
            <a href="/" className="catalog__nav-link">
                { props.value }
                <img src={ arrow } alt="arrow" />
            </a>
        </li>
    )
}

export default CatalogNavItem;

