import React from 'react';
import MainProductionData from './MainProductionData';
import MainProductionItem from './MainProductionItem';


const MainProductionItemsList = props => {
    return (
        <div className="production__body">
            {MainProductionData.map(item => (
            <MainProductionItem key={ item.id } { ...item } />
            ))}
        </div>
    )
}

export default MainProductionItemsList;